export const actionTypes = {
  GET_MDM_SUCCESS: 'GET_MDM_SUCCESS',
  GET_MDM_FAILURE: 'GET_MDM_FAILURE',
  GET_MDM_LOADER: 'GET_MDM_LOADER',

  OPEN_MDM_SUCCESS: 'OPEN_MDM_SUCCESS',
  OPEN_MDM_FAILURE: 'OPEN_MDM_FAILURE',
  OPEN_MDM_LOADER: 'OPEN_MDM_LOADER',

  APPROVE_OR_REJECT_MDM_SUCCESS: 'APPROVE_OR_REJECT_MDM_SUCCESS',
  APPROVE_OR_REJECT_MDM_FAILURE: 'APPROVE_OR_REJECT_MDM_FAILURE',
  APPROVE_OR_REJECT_LOADER: 'APPROVE_OR_REJECT_LOADER',

  SET_DATA_MDM: 'SET_DATA_MDM',
};
