import { actionTypes } from '../actions/actionTypes/resignationActionTypes';

let initialState = {
  ResignationReasons: [],
  IsEmployeeResigned: '',
  pyNote: '',
  resignationpyNote: '',
  pyID: '',
  Stage: '',
  postResignationLoader: false,
  getResignationLoader: false,
  hideResignpopup: false,
  ResignationCaseDetails: [],
  pyUserIdentifier: {},
  resignedCaseID: '',
  ShowChangePassword: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function resignation(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_RESIGNATION_SUCCESS:
      return {
        ...state,
        ResignationReasons: action?.data?.ResignationReasons || [],
        IsEmployeeResigned: action?.data?.IsEmployeeResigned || '',
        ResignationCaseDetails: action?.data?.ResignationCaseDetails || [],
        getResignationLoader: false,
        resignedCaseID: action?.data?.pyID,
        hideResignpopup: false,
        ShowChangePassword: action?.data?.ShowChangePassword === 'true' ? true : false,
      };
    case actionTypes.GET_RESIGNATION_FAILURE:
      return {
        ...state,
        getResignationLoader: false,
      };
    case actionTypes.GET_RESIGNATION_LOADER:
      return {
        ...state,
        getResignationLoader: true,
        hideResignpopup: false,
      };

    case actionTypes.INITIATE_RESIGNATION:
      return {
        ...state,
        ResignationReasons: action?.data?.ResignationReasons || [],
        ResignationCaseDetails: action?.data?.ResignationCaseDetails || [],
        IsEmployeeResigned: action?.data?.IsEmployeeResigned || '',
        postResignationLoader: false,
        hideResignpopup: true,
        resignationpyNote: action?.data?.data?.pyNote || '',
        Stage: action?.data?.data?.Stage || '',
        pyID: action?.data?.data?.pyID || '',
        pyUserIdentifier: action?.data?.pyUserIdentifier || '',
      };
    case actionTypes.POST_RESIGNATION_SUCCESS:
      return {
        ...state,
        ResignationReasons: action?.data?.ResignationReasons || [],
        ResignationCaseDetails: action?.data?.ResignationCaseDetails || [],
        IsEmployeeResigned: action?.data?.IsEmployeeResigned || '',
        postResignationLoader: false,
        hideResignpopup: true,
        pyNote: action?.data?.data?.pyNote || '',
        Stage: action?.data?.data?.Stage || '',
        pyID: action?.data?.data?.pyID || '',
        pyUserIdentifier: action?.data?.pyUserIdentifier || '',
      };
    case actionTypes.POST_RESIGNATION_FAILURE:
      return {
        ...state,
        postResignationLoader: false,
        // hideResignpopup: false,
      };
    case actionTypes.POST_RESIGNATION_LOADER:
      return {
        ...state,
        postResignationLoader: true,
      };

    case actionTypes.SET_RESIGNATION_DATA:
      return {
        ...state,
        hideResignpopup: false,
        pyNote: '',
        Stage: '',
        pyID: '',
        pyUserIdentifier: '',
      };

    default:
      return state;
  }
}
