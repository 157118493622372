export const actionTypes = {

  UPDATE_JOB_DESCRIPTION: 'UPDATE_JOB_DESCRIPTION',

  GET_TEMP_DEC_FAILURE: 'GET_TEMP_DEC_FAILURE',
  GET_TEMP_DEC_SUCCESS: 'GET_TEMP_DEC_SUCCESS',
  GET_TEMP_DEC_LOADER: 'GET_TEMP_DEC_LOADER',

  SET_TEMP_TYPE: 'SET_TEMP_TYPE',

  SAVE_TEMPLATE_FAILURE: 'SAVE_TEMPLATE_FAILURE',
  SAVE_TEMPLATE_SUCCESS: 'SAVE_TEMPLATE_SUCCESS',
  SAVE_TEMPLATE_LOADER: 'SAVE_TEMPLATE_LOADER',

  SET_DATA_TEMPLATES: 'SET_DATA_TEMPLATES',

  GET_CALL_TEMPLATE_FAILURE: 'GET_CALL_TEMPLATE_FAILURE',
  GET_CALL_TEMPLATE_SUCCESS: 'GET_CALL_TEMPLATE_SUCCESS',
  GET_CALL_TEMPLATE_LOADER: 'GET_CALL_TEMPLATE_LOADER',
};
