import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import { createRoot } from 'react-dom/client';
// if(process.env.NODE_ENV === "development"){
//   import("react-axe").then((axe) => {
//     axe.default(React, ReactDOM, 1000);
//   });
// }

const store = configureStore();

const root = createRoot(document.getElementById('root'));

root.render(
  <div
    onContextMenu={(e) => {
      if (process.env.NODE_ENV === 'production') {
        alert('Due to security reason,Right Click is not allowed.');
        e.preventDefault();
      }
    }}>
    <Provider store={store}>
      <App />
    </Provider>
  </div>
);

// ReactDOM.render(
//   <Provider store={store}>
//       <App />
//   </Provider>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
