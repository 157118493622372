import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

function LazyLoader({ isJobsEnabled }) {
  const theme = useTheme();

  const mobileMatchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isJobsEnabled ? (
        <Box m={2}>
          <Box m={2}>
            <Skeleton
              variant="rect"
              height={200}
              width={mobileMatchMediaQuery ? '100%' : '100%'}
            />
          </Box>

          <Box m={2}>
            <Skeleton
              variant="rect"
              height={200}
              width={mobileMatchMediaQuery ? '100%' : '100%'}
            />
          </Box>

          <Box m={2}>
            <Skeleton
              variant="rect"
              height={200}
              width={mobileMatchMediaQuery ? '100%' : '100%'}
            />
          </Box>
        </Box>
      ) : (
        <Box pt={0}>
          {/* <div style={{ display: 'flex' }}>
            <Skeleton
              variant="rect"
              height={60}
              width={mobileMatchMediaQuery ? '100%' : '93%'}
            />

            {!mobileMatchMediaQuery && (
              <Skeleton
                variant="circle"
                height={60}
                width={mobileMatchMediaQuery ? '10%' : '4%'}
                style={{ marginLeft: '16px' }}
              />
            )}
          </div> */}
          <div style={{ display: 'flex', marginTop: '16px' }}>
            {!mobileMatchMediaQuery && (
              <Skeleton
                variant="rect"
                height={700}
                style={{ marginRight: '16px', marginTop: '-16px' }}
                width={'3%'}
              />
            )}
            <Skeleton
              variant="rect"
              height={750}
              width={mobileMatchMediaQuery ? '100%' : '96%'}
              style={{
                marginLeft: !mobileMatchMediaQuery ? '0px' : '16px',
                marginTop: '16px',
                margin: mobileMatchMediaQuery ? '16px' : '0px',
              }}
            />
          </div>
        </Box>
      )}
    </>
  );
}

export default LazyLoader;
