export const actionTypes = {
  GET_GOALS_SUCCESS: 'GET_GOALS_SUCCESS',
  GET_GOALS_FAILURE: 'GET_GOALS_FAILURE',
  POST_GOALS_SUCCESS: 'POST_GOALS_SUCCESS',
  POST_GOALS_FAILURE: 'POST_GOALS_FAILURE',
  GET_TEAM_GOALS_SUCCESS: 'GET_TEAM_GOALS_SUCCESS',
  GET_TEAM_GOALS_FAILURE: 'GET_TEAM_GOALS_FAILURE',
  SHOW_LOADER_FOR_ORG_LOADER: 'SHOW_LOADER_FOR_ORG_LOADER',
  SHOW_LOADER_FOR_EMP_LOADER: 'SHOW_LOADER_FOR_EMP_LOADER',
  SET_DATA: 'SET_DATA',
  GET_MBO_INTERACTIONS_SUCCESS: 'GET_MBO_INTERACTIONS_SUCCESS',
  GET_MBO_INTERACTIONS_FAILURE: 'GET_MBO_INTERACTIONS_FAILURE',
  SHOW_LOADER_FOR_COMMENTS: 'SHOW_LOADER_FOR_COMMENTS',
  LOADER_FOR_ADD_COMMENTS: 'LOADER_FOR_ADD_COMMENTS',
  SET_DATA_REVIEWS: 'SET_DATA_REVIEWS',
  LOADER_FOR_UPDATE_ONEONONE: 'LOADER_FOR_UPDATE_ONEONONE',
  CLEAR_DATA_MBO: 'CLEAR_DATA_MBO',

  COMPLETE_HR_ASSESSSMENT_SUCCESS: 'COMPLETE_HR_ASSEsSSMENT_SUCCESS',
  COMPLETE_HR_ASSESSSMENT_FAILURE: 'COMPLETE_HR_ASSEsSSMENT_FAILURE',
  COMPLETE_HR_ASSESSSMENT_LOADER: 'COMPLETE_HR_ASSEsSSMENT_LOADER',

  GET_SLOTS_ONEONONE_SUCCESS: 'GET_SLOTS_ONEONONE_SUCCESS',
  GET_SLOTS_ONEONONE_FAILURE: 'GET_SLOTS_ONEONONE_FAILURE',
  GET_SLOTS_ONEONONE_LOADER: 'GET_SLOTS_ONEONONE_LOADER',

  ONEONONE_ACTIONS_SUCCESS: 'ONEONONE_ACTIONS_SUCCESS',
  ONEONONE_ACTIONS_FAILURE: 'ONEONONE_ACTIONS_FAILURE',
  ONEONONE_ACTIONS_LOADER: 'ONEONONE_ACTIONS_LOADER',

  SAVE_MBO_REVIEWS_LOADER: 'SAVE_MBO_REVIEWS_LOADER',

  OPEN_EMPTY_EXCEL_FORMATE_SUCCESS: 'OPEN_EMPTY_EXCEL_FORMATE_SUCCESS',
  OPEN_EMPTY_EXCEL_FORMATE_FAILURE: 'OPEN_EMPTY_EXCEL_FORMATE_FAILURE',
  OPEN_EMPTY_EXCEL_FORMATE_LOADER: 'OPEN_EMPTY_EXCEL_FORMATE_LOADER',
};
