import { actionTypes } from '../actions/actionTypes/templates';

let initialState = {
  OrgList: [],
  IsExistigTemp: false,
  getOrgListLoader: false,
  JobDescription: '',
  getTempDecLoader: false,
  resetScreen: false,
  saveTempLoader: false,
  Active: false,
  jobTempList: [],
  emailTemplates: [],
  Subject: '',
  getTemplatesLoader: false,
};
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function Templates(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CALL_TEMPLATE_SUCCESS:
      return {
        ...state,
        getTemplatesLoader: false,
        emailTemplates: action?.cases?.TemplateDecList || [],
        OrgList: action?.cases?.OrgList || [],
      };

    case actionTypes.GET_CALL_TEMPLATE_FAILURE:
      return {
        ...state,
        getTemplatesLoader: false,
        emailTemplates: [],
      };

    case actionTypes.GET_CALL_TEMPLATE_LOADER:
      return {
        ...state,
        getTemplatesLoader: true,
      };

    case actionTypes.UPDATE_JOB_DESCRIPTION:
      return {
        ...state,
        JobDescription: action?.cases?.JobDescription,
      };

    case actionTypes.GET_TEMP_DEC_SUCCESS:
      return {
        ...state,
        JobDescription: action?.cases?.JobDescription || action?.cases?.Message || '',
        tempJobDescription: action?.cases?.JobDescription || action?.cases?.Message || '',
        Subject: action?.cases?.Subject,
        IsExistigTemp: true,
        getTempDecLoader: false,
        Active: action?.cases?.Active,
      };

    case actionTypes.GET_TEMP_DEC_FAILURE:
      return {
        ...state,
        OrgList: [],
        getTempDecLoader: false,
      };

    case actionTypes.GET_TEMP_DEC_LOADER:
      return {
        ...state,
        getTempDecLoader: true,
      };

    case actionTypes.SET_TEMP_TYPE:
      return {
        ...state,
        IsExistigTemp: action?.cases?.IsExistigTemp,
      };

    case actionTypes.SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        saveTempLoader: false,
        resetScreen: true,
        IsExistigTemp: false,
        JobDescription: '',
        OrgList: action?.cases?.OrgList || [],
      };

    case actionTypes.SAVE_TEMPLATE_FAILURE:
      return {
        ...state,
        saveTempLoader: false,
      };

    case actionTypes.SAVE_TEMPLATE_LOADER:
      return {
        ...state,
        saveTempLoader: true,
      };

    case actionTypes.SET_DATA_TEMPLATES:
      return {
        ...state,
        resetScreen: false,
        tempJobDescription: '',
      };

    default:
      return state;
  }
}
