export const actionTypes = {
  POST_TIMEOFF_SUCCESS: 'POST_TIMEOFF_SUCCESS',
  POST_TIMEOFF_FAILURE: 'POST_TIMEOFF_FAILURE',
  GET_TIMEOFF_SUCCESS: 'GET_TIMEOFF_SUCCESS',
  GET_TIMEOFF_FAILURE: 'GET_TIMEOFF_FAILURE',
  GET_TEAM_TIMEOFF_SUCCESS: 'GET_TEAM_TIMEOFF_SUCCESS',
  GET_TEAM_TIMEOFF_FAILURE: 'GET_TEAM_TIMEOFF_FAILURE',
  POST_TEAM_STATUS_SUCCESS: 'POST_TEAM_STATUS_SUCCESS',
  POST_TEAM_STATUS_FAILURE: 'POST_TEAM_STATUS_FAILURE',
  SHOW_LOADER_TIMEOFF: 'SHOW_LOADER_TIMEOFF',
  SHOW_LOADER_TIMEOFF_HOLIDAYS: 'SHOW_LOADER_TIMEOFF_HOLIDAYS',
  SHOW_LOADER_TIMEOFF_LEAVES: 'SHOW_LOADER_TIMEOFF_LEAVES',
  SHOW_LOADER_TEAM_TIMEOFF_LEAVES: 'SHOW_LOADER_TEAM_TIMEOFF_LEAVES',
  POST_TEAM_STATUS_TIMEOFF_LOADER: 'POST_TEAM_STATUS_TIMEOFF_LOADER',
  SET_TIMEOFF_DATA: 'SET_TIMEOFF_DATA',

  TIMEOFF_WARNING: 'TIMEOFF_WARNING',

  WITHDRAW_LOADER: 'WITHDRAW_LOADER',
};
