import { Box, Button, CircularProgress, Grid, Link, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Helmet } from 'react-helmet';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import globalUseStyles from '../../config/themes/globalStyles';
import Google from '../../images/google.jpg';
import Microsoft from '../../images/microsoft.png';
import PeoplehcmLogo from '../../images/HCM_logo.png';
import oAuthAuthentication from '../../config/sso/microsoftsso';
import { useDispatch, useSelector } from 'react-redux';
import loginBg from '../../images/loginBg.jpg';
import image1 from '../../images/image1.svg';
import image3 from '../../images/image3.png';
import image2 from '../../images/HCM-software.png';
import evonsysLogo from '../../images/logo.png';
import minori_logo from '../../images/minori-logo.svg';
// import { blue } from '@mui/material/colors';
import { grey } from '@mui/material/colors';
import Signin from './loginPage';
import LoginStyles from './style';
import { ForgotPassword } from './ForgotPassword';

const getCompannyLogo = (logo) => {
  const classes = LoginStyles();

  if (logo === 'evonsys') {
    return <img src={evonsysLogo} alt="logo" className={classes.companyLogo} />;
  }
  if (logo === 'minori') {
    return <img src={minori_logo} alt="logo" className={classes.companyLogo} />;
  }
  return null;
};

const ColorButton = withStyles({
  root: {
    color: grey[500],
    border: `1px solid ${grey[500]}`,

    '&:hover': {
      border: `1px solid ${grey[700]}`,
    },
  },
})((props) => <Button color="inherit" {...props} />);

function NewLoginPage({ SSOLogin }) {
  const theme = useTheme();
  const mobileMatchMediaQuery = useMediaQuery(theme.breakpoints.down('md'));
  const globalStyles = globalUseStyles();
  const dispatch = useDispatch();
  const loginLoader = useSelector((state) => state.loggedIn.loginLoader);
  const authType = useSelector((state) => state.loggedIn.authType);
  const [isForgotPasswordEnabled, setForgotPasswordEnable] = React.useState(false);

  function microsoftLogin() {
    oAuthAuthentication(dispatch, 'microsoft').onOAuthBtnClick();
  }

  function googleLogin() {
    oAuthAuthentication(dispatch, 'google').onOAuthBtnClick();
  }

  return (
    <div
      className="loginPage"
      style={{
        backgroundImage: mobileMatchMediaQuery
          ? 'none'
          : `linear-gradient(rgba(255,255,255,.7), rgba(255,255,255,.9)), url(${loginBg})`,
        backgroundSize: 'cover',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Helmet>
        <title>People HCM - {'Login'}</title>
      </Helmet>

      <Paper
        style={{
          backgroundColor: 'white !important',
          margin: mobileMatchMediaQuery ? '0px' : '100px',
        }}
        elevation={mobileMatchMediaQuery ? 0 : 2}>
        {mobileMatchMediaQuery && (
          <Grid container alignItems="center" justifyContent="flex-end">
            {getCompannyLogo(window.logo)}
          </Grid>
        )}
        <Box
          mt={mobileMatchMediaQuery ? 2 : -1}
          //   pt={mobileMatchMediaQuery ? 2 : 0}
          ml={mobileMatchMediaQuery ? 0 : -1}>
          <Grid container>
            {mobileMatchMediaQuery && (
              <Grid item lg={12} xs={12}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{
                    fontWeight: 700,
                    marginBottom: '-16px !important',
                    // color: '#717171',
                  }}>
                  Welcome to
                </Typography>
              </Grid>
            )}
            {mobileMatchMediaQuery && (
              <Box style={{ margin: '0 auto ', marginBottom: '30px' }} pt={3}>
                <img src={PeoplehcmLogo} alt="logo" style={{ width: '250px' }} />
              </Box>
            )}

            <Grid
              item
              lg={8}
              style={{
                backgroundColor: mobileMatchMediaQuery ? 'white' : '#3866fa',
                display: 'flex',
                flexDirection: 'column !important',
                alignItems: 'center !important',
                color: 'white',
                justifyContent: 'center !important',
                marginTop: '8px !important',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  className={globalStyles.flexRowAlignCenter}>
                  {!mobileMatchMediaQuery && (
                    <img
                      src={image1}
                      alt="computer use"
                      style={{
                        maxWidth: mobileMatchMediaQuery ? '65%' : '20%',
                        // display: 'inline-block',
                        // margin: mobileMatchMediaQuery ? '0 auto' : '0 auto 30px',
                      }}
                    />
                  )}

                  {!mobileMatchMediaQuery && (
                    <img
                      src={image3}
                      alt="computer use"
                      style={{
                        maxWidth: mobileMatchMediaQuery ? '30%' : '25%',
                        // display: 'inline-block',
                        // margin: mobileMatchMediaQuery ? '0 auto' : '0 auto 30px',
                      }}
                    />
                  )}
                  {!mobileMatchMediaQuery && (
                    <img
                      src={image2}
                      alt="computer use"
                      style={{
                        maxWidth: mobileMatchMediaQuery ? '70%' : '25%',
                        // display: 'inline-block',
                        // margin: mobileMatchMediaQuery ? '0 auto' : '0 auto 30px',
                      }}
                    />
                  )}
                </Grid>

                {!mobileMatchMediaQuery && (
                  <Box mb={2} mt={3}>
                    <Typography variant="h5" style={{ marginBottom: '-5px' }}>
                      New here?
                    </Typography>
                    <Typography variant="caption">
                      Please reach out to HR/Resource Manager to proceed further
                    </Typography>
                    <Link
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        display: 'block',
                        marginTop: '16px',
                      }}
                      href={'https://peoplehcm.com/'}
                      target="_blank">
                      <Button color="inherit" variant="outlined">
                        <Typography variant="caption">Explore Peoplehcm</Typography>
                      </Button>
                    </Link>
                  </Box>
                )}
              </div>
            </Grid>

            <Grid
              item
              lg={4}
              xs={12}
              style={{
                height: mobileMatchMediaQuery ? '150px' : '500px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-around',
                flexDirection: 'column',
              }}>
              {!mobileMatchMediaQuery && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  style={{ position: 'relative', top: 20 }}>
                  {getCompannyLogo(window.logo)}
                </Grid>
              )}

              {SSOLogin ? (
                <Grid
                  container
                  spacing={3}
                  style={{
                    marginTop: !mobileMatchMediaQuery ? '-150px !important' : '0px !important',
                    display: 'flex !important',
                    flexDirection: 'column !important',
                    justifyContent: 'center !important',
                    alignContent: 'center !important',
                    alignItems: 'center !important',
                  }}>
                  <Grid item md={12} xs={12} className={globalStyles.flexColumn}>
                    {!mobileMatchMediaQuery && (
                      <Typography
                        variant="h6"
                        style={{ fontWeight: 500, marginBottom: '-5px', color: 'black' }}>
                        Welcome to
                      </Typography>
                    )}
                    {!mobileMatchMediaQuery && (
                      <img src={PeoplehcmLogo} alt="logo" style={{ width: '250px' }} />
                    )}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={globalStyles.flexColumn}
                    style={{ marginBottom: '-16px', color: 'black' }}>
                    <Typography variant="subtitle2">Choose your login</Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={globalStyles.flexRowAlignCenter}
                    style={{ justifyContent: 'center' }}>
                    <Box mr={2}>
                      <ColorButton
                        size="large"
                        disabled={loginLoader}
                        variant="outlined"
                        onClick={microsoftLogin}
                        className={globalStyles.flexRowAlignCenter}>
                        <img
                          src={Microsoft}
                          alt="logo"
                          style={{ width: '35px', padding: '6px' }}
                        />
                        &nbsp; Microsoft &nbsp;&nbsp;
                        {loginLoader && authType === 'microsoft' && (
                          <CircularProgress size={20} />
                        )}
                      </ColorButton>
                    </Box>
                    <ColorButton
                      size="large"
                      variant="outlined"
                      disabled={loginLoader}
                      className={globalStyles.flexRowAlignCenter}
                      onClick={googleLogin}>
                      <img src={Google} alt="logo" style={{ width: '35px' }} />
                      &nbsp; Google &nbsp;&nbsp;
                      {loginLoader && authType === 'google' && (
                        <span>
                          &nbsp;&nbsp;
                          <CircularProgress size={20} />
                        </span>
                      )}
                    </ColorButton>
                  </Grid>
                  {/* {loginLoader && (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      variant="subtitle1"
                      className={globalStyles.flexRowAlignCenter}>
                      Logging in...&nbsp;&nbsp;
                      <CircularProgress size={25} />{' '}
                    </Typography>
                  </Grid>
                )} */}
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={3}
                  style={{
                    marginTop: !mobileMatchMediaQuery ? '-150px !important' : '0px !important',
                    display: 'flex !important',
                    flexDirection: 'column !important',
                    justifyContent: 'center !important',
                    alignContent: 'center !important',
                    alignItems: 'center !important',
                  }}>
                  <Grid item md={12} xs={12}>
                    {isForgotPasswordEnabled ? (
                      <ForgotPassword setForgotPasswordEnable={setForgotPasswordEnable} />
                    ) : (
                      <Signin
                        mobileMatchMediaQuery={mobileMatchMediaQuery}
                        SSOLogin={SSOLogin}
                        setForgotPasswordEnable={setForgotPasswordEnable}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}

export default NewLoginPage;
