import { actionTypes } from '../actions/actionTypes/reportActionTypes';

let initialState = {
  ReportData: [],
  postRportData: [],
  reportLoader: false,
  filterData: [],
  getFilterDataLoader: false,
  getReportsLoader: false,
  sendReportThroughMailLoader: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function reports(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        ReportData: (action && action.cases) || [],
        reportLoader: false,
        getReportsLoader: false,
        postRportData: [],
      };

    case actionTypes.GET_REPORTS_FAILURE:
      return {
        ...state,
        ReportData: [],
        reportLoader: false,
        getReportsLoader: false,
        postRportData: [],
      };

    case actionTypes.GET_REPORTS_LOADER:
      return {
        ...state,
        ReportData: [],
        reportLoader: false,
        getReportsLoader: true,
        postRportData: [],
      };

    case actionTypes.POST_REPORTS_SUCCESS:
      return {
        ...state,
        postRportData: (action && action.cases) || [],
        reportLoader: false,
      };

    case actionTypes.POST_REPORTS_FAILURE:
      return {
        ...state,
        postRportData: [],
        reportLoader: false,
      };
    case actionTypes.SHOW_LOADER_REPORTS:
      return {
        ...state,
        reportLoader: true,
      };

    case actionTypes.SET_REPORTS_DATA:
      return {
        ...state,
        postRportData: [],
        // filterData: [],
      };

    case actionTypes.GET_FILTER_DATA_SUCCESS:
      return {
        ...state,
        filterData: action?.cases?.pxResults || [],
        getFilterDataLoader: false,
      };
    case actionTypes.GET_FILTER_DATA_LOADER:
      return {
        ...state,
        getFilterDataLoader: true,
      };
    case actionTypes.GET_FILTER_DATA_FAILURE:
      return {
        ...state,
        filterData: [],
        getFilterDataLoader: false,
      };
    case actionTypes.CLEAR_FILTER_DATA:
      return {
        ...state,
        filterData: [],
      };
    case actionTypes.SEND_REPORT_THROUGH_MAIL_SUCCESS:
      return {
        ...state,
        sendReportThroughMailLoader: false,
      };
    case actionTypes.SEND_REPORT_THROUGH_MAIL_FAILURE:
      return {
        ...state,
        sendReportThroughMailLoader: false,
      };
    case actionTypes.SEND_REPORT_THROUGH_MAIL_LOADER:
      return {
        ...state,
        sendReportThroughMailLoader: true,
      };
    default:
      return state;
  }
}
