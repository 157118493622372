export const actionTypes = {
  GET_RESIGNATION_SUCCESS: 'GET_RESIGNATION_SUCCESS',
  GET_RESIGNATION_FAILURE: 'GET_RESIGNATION_FAILURE',
  GET_RESIGNATION_LOADER: 'GET_RESIGNATION_LOADER',

  POST_RESIGNATION_SUCCESS: 'POST_RESIGNATION_SUCCESS',
  POST_RESIGNATION_FAILURE: 'POST_RESIGNATION_FAILURE',
  POST_RESIGNATION_LOADER: 'POST_RESIGNATION_LOADER',

  SET_RESIGNATION_DATA: 'SET_RESIGNATION_DATA',

  INITIATE_RESIGNATION: 'INITIATE_RESIGNATION',
};
