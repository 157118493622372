import { actionTypes } from '../actions/actionTypes/profleActionType';

let initialState = {
  profileData: {},
  showResignLoader: false,
  hidepopup: false,
  BasicInformation: {},
  PrimaryAddress: {},
  CommAddress: {},
  PrimaryPhoneNumber: {},
  WhatsAppNumber: {},
  SecondaryPhoneNumber: {},
  WorkAddress: {},
  showLoaderForGet: false,
  showLoaderForCommunicationAddressUpdate: false,
  showLoaderForPrimaryAddressUpdate: false,
  showLoaderForPrimaryPhoneNumberUpdate: false,
  showLoaderForSecondaryPhoneNumberUpdate: false,
  showLoaderForWhatsAppNumberUpdate: false,
  getAddressLoader: false,
  showLoaderForSkillUpdate: false,
  skills: [],
  addressFromZipCode: {},
  AvailableSkillList: [],
  Announcements: [],
  EmpCelebrationDetails: [],
  UpcomingHolidays: [],
  disableAllProfileButtons: false,
  showResignation: false,
  ResignationId: '',
  FinanceOrgDetails: [],
  HasEmpDwollaIDExist: false,
  IsOrgFinanceManagerExist: false,
  accessTokenData: {},
  getAccessTokenLoader: false,
  employerIAVToken: '',
  employerIAVTokenLoader: false,
  empCustomerIDLoader: false,
  showFunding: true,
  HasEmpFundingSourceExist: false,
  EmpDwollaFundingStatus: '',
  DwollaLinkedBankName: '',
  ShowDwollaidForEmployee: false,
  EnableEmployeeFundingSourceRemoval: false,
  removeFSLoader: false,
  verificationInfo: {},
  kbaQuestions: [],
  sendDocumentIDLoader: false,
  sendAvailableSlotsloader: false,
  sendNotAvailableSlotsloader: false,
  overrideSlotsloader: false,
  overrideSlotsData: [],
  validateOverrides: {},
  CalendarOverride: false,
  overrideCalenderloader: false,
  verifyMicroDepositsLoader: false,
  plaidLinkToken: '',
  plaidLinkTokenLoader: false,
  tokenData: {},
  validateOverlap: {
    Sunday: {
      overlaps: [],
      isOverlapped: false,
    },
    Monday: {
      overlaps: [],
      isOverlapped: false,
    },
    Tuesday: {
      overlaps: [],
      isOverlapped: false,
    },
    Wednesday: {
      overlaps: [],
      isOverlapped: false,
    },
    Thursday: {
      overlaps: [],
      isOverlapped: false,
    },
    Friday: {
      overlaps: [],
      isOverlapped: false,
    },
    Saturday: {
      overlaps: [],
      isOverlapped: false,
    },
  },
  PanelMemberAbsence: [],
  sendPlaidPublicTokenLoader: false,
  removePlaidAccountLoader: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function profile(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profileData: action?.profileData || {},
        accessTokenData: {},
        employerIAVToken: '',
        skills: action?.profileData?.SkillSet || [],
        BasicInformation: action?.profileData?.BasicInformation || {},
        WorkAddress: action?.profileData?.WorkAddress || {},
        PrimaryAddress: action?.profileData?.PrimaryAddress || {},
        AvailableSkillList: action?.profileData?.AvailableSkillList || [],
        PanelMemberAbsence: action?.profileData?.PanelMemberAbsence || [],
        CommAddress: action?.profileData?.CommAddress || {},
        PrimaryPhoneNumber: action?.profileData?.PrimaryPhoneNumber || {},
        WhatsAppNumber: action?.profileData?.WhatsAppNumber || {},
        DwollaLinkedBankName: action?.profileData?.DwollaLinkedBankName,
        CalendarOverride: action?.profileData?.CalendarOverride === 'true' ? true : false,
        showLoaderForGet: false,
        showResignation: action?.profileData?.IsEmployeeResigned === 'true' ? true : false,
        ShowDwollaidForEmployee:
          action?.profileData?.ShowDwollaidForEmployee === 'true' ? true : false,
        HasEmpFundingSourceExist:
          action?.profileData?.HasEmpFundingSourceExist === 'true' ? true : false,

        EmpDwollaFundingStatus: action?.profileData?.EmpDwollaFundingStatus,
        EnableEmployeeFundingSourceRemoval:
          action?.profileData?.EnableEmployeeFundingSourceRemoval,
        verifyMicroDepositsLoader: false,
        ResignationId: action?.profileData?.ResignationId,
        SecondaryPhoneNumber: action?.profileData?.SecondaryPhoneNumber || {},
        Announcements: action?.profileData?.Announcements || [],
        EmpCelebrationDetails: action?.profileData?.EmpCelebrationDetails || [],
        UpcomingHolidays: action?.profileData?.UpcomingHolidays || [],
        disableAllProfileButtons: false,
        FinanceOrgDetails: action?.profileData?.FinanceOrgDetails || [],
        HasEmpDwollaIDExist:
          action?.profileData?.HasEmpDwollaIDExist === 'true' ? true : false,
        IsOrgFinanceManagerExist:
          action?.profileData?.IsOrgFinanceManagerExist === 'true' ? true : false,
      };

    case actionTypes.GET_PROFILE_DATA_FAILURE:
      return {
        ...state,
        profileData: {},
        BasicInformation: {},
        showLoaderForGet: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.GET_PROFILE_DATA_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        showLoaderForGet: true,
      };

    case actionTypes.POST_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profileData: action?.profileData || {},
        employerIAVToken: '',
        showResignLoader: false,
        hidepopup: true,
        disableAllProfileButtons: false,
      };

    case actionTypes.POST_PROFILE_DATA_FAILURE:
      return {
        ...state,
        disableAllProfileButtons: false,
        showResignLoader: false,
      };

    case actionTypes.SHOW_LOADER_FOR_RESIGN_LOADER:
      return {
        ...state,
        showResignLoader: true,
        disableAllProfileButtons: true,
        hidepopup: false,
      };

    case actionTypes.PERMENENT_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        PrimaryAddress: action?.profileData?.PrimaryAddress || {},
        showLoaderForPrimaryAddressUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.PERMENENT_ADDRESS_UPDATE_FAILURE:
      return {
        ...state,
        showLoaderForPrimaryAddressUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.PERMENENT_ADDRESS_UPDATE_LOADER:
      return {
        ...state,
        showLoaderForPrimaryAddressUpdate: true,
        disableAllProfileButtons: true,
      };

    case actionTypes.COMM_ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        CommAddress: action?.profileData?.CommAddress || {},
        showLoaderForCommunicationAddressUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.COMM_ADDRESS_UPDATE_FAILURE:
      return {
        ...state,
        showLoaderForCommunicationAddressUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.COMM_ADDRESS_UPDATE_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        showLoaderForCommunicationAddressUpdate: true,
      };

    case actionTypes.PRIMARY_PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        PrimaryPhoneNumber: action?.profileData?.PrimaryPhoneNumber || {},
        showLoaderForPrimaryPhoneNumberUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.PRIMARY_PHONE_UPDATE_FAILURE:
      return {
        ...state,
        showLoaderForPrimaryPhoneNumberUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.PRIMARY_PHONE_UPDATE_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        showLoaderForPrimaryPhoneNumberUpdate: true,
      };

    case actionTypes.SECONDARY_PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        SecondaryPhoneNumber: action?.profileData?.SecondaryPhoneNumber || {},
        showLoaderForSecondaryPhoneNumberUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.SECONDARY_PHONE_UPDATE_FAILURE:
      return {
        ...state,
        showLoaderForSecondaryPhoneNumberUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.SECONDARY_PHONE_UPDATE_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        showLoaderForSecondaryPhoneNumberUpdate: true,
      };

    case actionTypes.WHATSUP_PHONE_UPDATE_SUCCESS:
      return {
        ...state,
        WhatsAppNumber: action?.profileData?.WhatsAppNumber || {},
        disableAllProfileButtons: false,
        showLoaderForWhatsAppNumberUpdate: false,
      };

    case actionTypes.WHATSUP_PHONE_UPDATE_FAILURE:
      return {
        ...state,
        showLoaderForWhatsAppNumberUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.WHATSUP_PHONE_UPDATE_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        showLoaderForWhatsAppNumberUpdate: true,
      };

    case actionTypes.GET_ADDRESS_SUCCESS:
      return {
        ...state,
        getAddressLoader: false,
        disableAllProfileButtons: false,
        addressFromZipCode: action?.data || {},
      };

    case actionTypes.GET_ADDRESS_FAILURE:
      return {
        ...state,
        getAddressLoader: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.GET_ADDRESS_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        getAddressLoader: true,
      };

    case actionTypes.SKILL_UPDATE_SUCCESS:
      return {
        ...state,
        showLoaderForSkillUpdate: false,
        disableAllProfileButtons: false,
        skills: action?.profileData?.SkillSet || [],
      };

    case actionTypes.SKILLE_UPDATE_FAILURE:
      return {
        ...state,
        showLoaderForSkillUpdate: false,
        disableAllProfileButtons: false,
      };

    case actionTypes.SKILL_UPDATE_LOADER:
      return {
        ...state,
        disableAllProfileButtons: true,
        showLoaderForSkillUpdate: true,
      };

    case actionTypes.SET_DATA_PROFILE:
      return {
        ...state,
        addressFromZipCode: {},
        employerIAVToken: '',
        accessTokenData: {},
        showFunding: true,
        verificationInfo: {},
        kbaQuestions: [],
      };

    case actionTypes.GET_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessTokenData: action?.profileData,
        getAccessTokenLoader: false,
        showFunding: true,
      };
    case actionTypes.GET_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        accessTokenData: {},
        getAccessTokenLoader: false,
      };
    case actionTypes.GET_ACCESS_TOKEN_LOADER:
      return {
        ...state,
        getAccessTokenLoader: true,
      };

    case actionTypes.EMPLOYER_ID_SENDING_SUCCESS:
      return {
        ...state,
        profileData: action?.profileData || {},
        empCustomerIDLoader: false,
        sendDocumentIDLoader: false,
        sendAvailableSlotsloader: false,
        sendNotAvailableSlotsloader: false,
        showFunding: true,
        verificationInfo: {},
        employerIAVToken: '',
        kbaQuestions: [],
        skills: action?.profileData?.SkillSet || [],
        BasicInformation: action?.profileData?.BasicInformation || {},
        WorkAddress: action?.profileData?.WorkAddress || {},
        PrimaryAddress: action?.profileData?.PrimaryAddress || {},
        AvailableSkillList: action?.profileData?.AvailableSkillList || [],
        CommAddress: action?.profileData?.CommAddress || {},
        PrimaryPhoneNumber: action?.profileData?.PrimaryPhoneNumber || {},
        WhatsAppNumber: action?.profileData?.WhatsAppNumber || {},
        showLoaderForGet: false,
        DwollaLinkedBankName: action?.profileData?.DwollaLinkedBankName,
        showResignation: action?.profileData?.IsEmployeeResigned === 'true' ? true : false,
        ResignationId: action?.profileData?.ResignationId,
        SecondaryPhoneNumber: action?.profileData?.SecondaryPhoneNumber || {},
        Announcements: action?.profileData?.Announcements || [],
        EmpCelebrationDetails: action?.profileData?.EmpCelebrationDetails || [],
        UpcomingHolidays: action?.profileData?.UpcomingHolidays || [],
        accessTokenData: {},
        employerIAVToken: '',
        disableAllProfileButtons: false,
        FinanceOrgDetails: action?.profileData?.FinanceOrgDetails || [],
        HasEmpFundingSourceExist:
          action?.profileData?.HasEmpFundingSourceExist === 'true' ? true : false,

        EnableEmployeeFundingSourceRemoval:
          action?.profileData?.EnableEmployeeFundingSourceRemoval,
        EmpDwollaFundingStatus: action?.profileData?.EmpDwollaFundingStatus,
        HasEmpDwollaIDExist:
          action?.profileData?.HasEmpDwollaIDExist === 'true' ? true : false,
        IsOrgFinanceManagerExist:
          action?.profileData?.IsOrgFinanceManagerExist === 'true' ? true : false,
      };
    case actionTypes.EMPLOYER_ID_SENDING_FAILURE:
      return {
        ...state,
        showLoaderForGet: false,
        sendDocumentIDLoader: false,
      };

    case actionTypes.EMPLOYER_ID_SENDING_LOADER:
      return {
        ...state,
        showLoaderForGet: true,
      };

    case actionTypes.EMPLOYER_IAV_TOKEN_SUCCESS:
      return {
        ...state,
        employerIAVToken: action?.profileData?.IAVToken,
        tokenData: action?.profileData,
        employerIAVTokenLoader: false,
      };
    case actionTypes.EMPLOYER_IAV_TOKEN_FAILURE:
      return {
        ...state,
        employerIAVTokenLoader: false,
      };
    case actionTypes.EMPLOYER_IAV_TOKEN_LOADER:
      return {
        ...state,
        employerIAVTokenLoader: true,
      };

    case actionTypes.REMOVE_FS_SUCCESS:
      return {
        ...state,
        removeFSLoader: false,
      };
    case actionTypes.REMOVE_FS_FAILURE:
      return {
        ...state,
        removeFSLoader: false,
      };
    case actionTypes.REMOVE_FS_LOADER:
      return {
        ...state,
        removeFSLoader: true,
      };

    case actionTypes.VERIFY_CUSTOMER_CREATION_SUCCESS:
      return {
        ...state,
        verificationInfo: action?.profileData,
        // accessTokenData:action?.profileData,
        kbaQuestions: action?.profileData?.questions || [],
        getAccessTokenLoader: false,
      };
    case actionTypes.VERIFY_CUSTOMER_CREATION_FAILURE:
      return {
        ...state,
        getAccessTokenLoader: false,
      };
    case actionTypes.GET_KBA_QUESTIONS_SUCCESS:
      return {
        ...state,
        showLoaderForGet: false,
      };
    case actionTypes.GET_KBA_QUESTIONS_SUCCESS:
      return {
        ...state,
        showLoaderForGet: false,
      };

    case actionTypes.SEND_DOCUMENT_ID_LOADER:
      return {
        ...state,
        sendDocumentIDLoader: true,
      };
    case actionTypes.SET_SLOTS_AVAILABLE:
      return {
        ...state,
        validateOverlap: action?.profileData,
      };
    case actionTypes.SET_OVERRIDE_DATA:
      return {
        ...state,
        validateOverrides: action?.profileData,
      };

    case actionTypes.AVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        sendAvailableSlotsloader: false,
      };

    case actionTypes.AVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        sendAvailableSlotsloader: false,
      };
    case actionTypes.AVAILABLE_SLOTS_LOADER:
      return {
        ...state,
        sendAvailableSlotsloader: true,
      };

    case actionTypes.NOTAVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        sendNotAvailableSlotsloader: false,
      };

    case actionTypes.NOTAVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        sendNotAvailableSlotsloader: false,
        PanelMemberAbsence: action?.profileData?.PanelMemberAbsence || [],
      };
    case actionTypes.NOTAVAILABLE_SLOTS_LOADER:
      return {
        ...state,
        sendNotAvailableSlotsloader: true,
      };

    case actionTypes.OVERRIDE_SLOTS_SUCCESS:
      return {
        ...state,
        overrideSlotsloader: false,
        overrideSlotsData: action?.profileData?.GetPanelMemberOverrideDetails || [],
      };
    case actionTypes.OVERRIDE_SLOTS_FAILURE:
      return {
        ...state,
        overrideSlotsloader: false,
      };
    case actionTypes.OVERRIDE_SLOTS_LOADER:
      return {
        ...state,
        overrideSlotsloader: true,
      };

    case actionTypes.OVERRIDE_CALENDER_SUCCESS:
      return {
        ...state,
        overrideCalenderloader: false,
        CalendarOverride: action?.profileData?.CalendarOverride === 'true' ? true : false,
      };
    case actionTypes.OVERRIDE_CALENDER_FAILURE:
      return {
        ...state,
        overrideCalenderloader: false,
      };
    case actionTypes.OVERRIDE_CALENDER_LOADER:
      return {
        ...state,
        overrideCalenderloader: true,
      };

    case actionTypes.VERIFY_MICRO_DEPOSITS_SUCCESS:
      return {
        ...state,
        verifyMicroDepositsLoader: false,
      };
    case actionTypes.VERIFY_MICRO_DEPOSITS_FAILURE:
      return {
        ...state,
        verifyMicroDepositsLoader: false,
      };
    case actionTypes.VERIFY_MICRO_DEPOSITS_LOADER:
      return {
        ...state,
        verifyMicroDepositsLoader: true,
      };

    case actionTypes.GET_PLAID_TOKEN_SUCCESS:
      return {
        ...state,
        plaidLinkToken: action?.profileData?.LinkToken,
        plaidLinkTokenLoader: false,
      };
    case actionTypes.GET_PLAID_TOKEN_FAILURE:
      return {
        ...state,
        plaidLinkToken: '',
        plaidLinkTokenLoader: false,
      };
    case actionTypes.GET_PLAID_TOKEN_LOADER:
      return {
        ...state,
        plaidLinkTokenLoader: true,
      };

    case actionTypes.CLEAR_PLAID_TOKEN:
      return {
        ...state,
        plaidLinkToken: '',
      };

    case actionTypes.SEND_PLAID_PUBLIC_TOKEN_SUCCESS:
      return {
        ...state,
        sendPlaidPublicTokenLoader: false,
      };

    case actionTypes.SEND_PLAID_PUBLIC_TOKEN_FAILURE:
      return {
        ...state,
        sendPlaidPublicTokenLoader: false,
      };

    case actionTypes.SEND_PLAID_PUBLIC_TOKEN_LOADER:
      return {
        ...state,
        sendPlaidPublicTokenLoader: true,
      };

    case actionTypes.REMOVE_PLAID_ACCOUNT_SUCCESS:
      return {
        ...state,
        removePlaidAccountLoader: false,
      };

    case actionTypes.REMOVE_PLAID_ACCOUNT_FAILURE:
      return {
        ...state,
        removePlaidAccountLoader: false,
      };

    case actionTypes.REMOVE_PLAID_ACCOUNT_LOADER:
      return {
        ...state,
        removePlaidAccountLoader: true,
      };

    default:
      return state;
  }
}
