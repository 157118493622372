export const startTimer = (duration, display) => {
  var start = Date.now(),
    diff,
    minutes,
    seconds;
  function timer() {
    // get the number of seconds that have elapsed since
    // startTimer() was called
    diff = duration - (((Date.now() - start) / 1000) | 0);

    // does the same job as parseInt truncates the float
    minutes = (diff / 60) | 0;
    seconds = diff % 60 | 0;

    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    display.textContent = minutes + ':' + seconds;

    if (diff <= 0) {
      display.textContent = '';
      clearInterval(myInterval);
    }
  }
  // we don't want to wait a full second before the timer starts
  timer();

  const myInterval = setInterval(timer, 1000);
};
