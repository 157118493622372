export const actionTypes = {
  GET_OFFBOARDING_SUCCESS: 'GET_OFFBOARDING_SUCCESS',
  GET_OFFBOARDING_FAILURE: 'GET_OFFBOARDING_FAILURE',
  GET_OFFBOARDING_LOADER: 'GET_OFFBOARDING_LOADER',

  CREATE_OFFBOARD_SUCCESS: 'CREATE_OFFBOARD_SUCCESS',
  CREATE_OFFBOARD_FAILURE: 'CREATE_OFFBOARD_FAILURE',
  CREATE_OFFBOARD_LOADER: 'CREATE_OFFBOARD_LOADER',

  CLEAR_OFFBOARD_DATA: 'CLEAR_OFFBOARD_DATA',
  SET_COMMUNICATION_DATA: 'SET_COMMUNICATION_DATA',
};
