import { actionTypes } from '../actions/actionTypes/ATSActionType';

let initialState = {
  ATSOptionMenu: [],
  IsRecruiter: false,
  Jobs: [],
  APLCommonpoolCaseList: [],
  AssignedApplicantCaseList: [],
  PendingApplicantsJobCases: [],
  UpdateTZMsg: '',
  ATSJobCreationLoader: false,
  ATSJobCreationBAsicData: {},
  postATSJobCreationLoader: false,
  EmpLocData: [],
  HRLocData: [],
  GetJobSKills: [],
  CountryBasedOnLocCode: [],
  getEmpLoader: false,
  jobSearchLoader: false,
  jobsList: [],
  applicantData: {},
  submitApplicantLoader: false,
  bulkAssignLoader: false,
  bulkAssignMsg: '',
  bulkAssig: {},
  jobDetails: {},
  jobDetailsLoader: false,
  OrgList: [],
  ReferredApplicant: [],
  ATSAllJobCases: [],
  pagination: 0,
  CreatedApplicantCaseList: [],
  searchTempLoader: false,
  JobDescription: '',
  getCasesForDispositionEmailLoader: false,
  casesOfBulkEmailDisposition: [],
  BulkTempletList: [],
  sucessNoteEmailDisposition: '',
  sendDispositionEmailLoader: false,
  InterviewScheduleData: {},
  ScheduleInterviewLoader: false,
  InterviewPanelMembers: [],
  getPanelMembersLoader: false,
  notifyPanelLoader: false,
  NoOfPanelMembers: 0,
  lastThreeMonthsCases: [],
  searchKeyATS: '',
  Country: '',
};
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function ATS(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ATS_JOB_CREATION_SUCCESS:
      return {
        ...state,
        ATSOptionMenu: action.cases?.ATSOptionMenu || [],
        ApplicantCaseList: action.cases?.ApplicantCaseList,
        CreatedApplicantCaseList: action.cases?.CreatedApplicantCaseList,
        PendingApplicantsJobCases: action.cases?.PendingApplicantsJobCases,
        AssignedApplicantCaseList: action.cases?.AssignedApplicantCaseList,
        APLCommonpoolCaseList: action.cases?.APLCommonpoolCaseList,
        ReferredApplicant: action.cases?.ReferredApplicant,
        pagination: parseInt(action?.cases?.PageIndexCount),
        Jobs: action.cases?.ATSJobData,
        ATSAllJobCases: action.cases?.ATSAllJobCases,
        ATSJobCreationBAsicData: {},
        IsRecruiter: action?.cases?.IsRecruiter === 'true' ? true : false,
        applicantData: {},
        ATSJobCreationLoader: false,
        CountryList: action.cases?.CountryList || [],
        bulkAssignMsg: '',
        UpdateTZMsg: action?.cases?.UpdateTZMsg,
        NewApplicantCasesCount: action?.cases?.NewApplicantCasesCount,
        PendingCurrentWeekInterviewCasesCount:
          action?.cases?.PendingCurrentWeekInterviewCasesCount,
        PendingInterviewCasesCount: action?.cases?.PendingInterviewCasesCount,
        PendingOfferCasesCount: action?.cases?.PendingOfferCasesCount,
        PendingScreeningCasesCount: action?.cases?.PendingScreeningCasesCount,
        PendingFeedback: action?.cases?.PendingFeedbackCasesCount,
        lastThreeMonthsCases: action?.cases?.ApplicantCasesCount || [],
        ScreeningApplicantCasesCount: action?.cases?.ScreeningApplicantCasesCount,
        ApplicantsSelectedCount: action?.cases?.ApplicantsSelectedCount,
        RejectedApplicantCasesCount: action?.cases?.RejectedApplicantCasesCount,
        OffersExtendedCount: action?.cases?.OffersExtendedCount,
        OffersAcceptedCount: action?.cases?.OffersAcceptedCount,
        OnboardedCasesCount: action?.cases?.OnboardedCasesCount,
      };

    case actionTypes.GET_ATS_JOB_CREATION_FAILURE:
      return {
        ...state,
        ATSOptionMenu: [],
        applicantData: {},
        ATSJobCreationBAsicData: {},
        ATSJobCreationLoader: false,
        bulkAssignMsg: '',
      };

    case actionTypes.GET_ATS_JOB_CREATION_LOADER:
      return {
        ...state,
        applicantData: {},
        ATSJobCreationBAsicData: {},
        ATSJobCreationLoader: true,
      };
    case actionTypes.POST_ATS_JOB_CREATION_SUCCESS:
      return {
        ...state,
        ATSJobCreationBAsicData: action.cases,
        postATSJobCreationLoader: false,
        OrgList: action.cases.OrgDetails || [],
      };

    case actionTypes.POST_ATS_JOB_CREATION_FAILURE:
      return {
        ...state,
        ATSJobCreationBAsicData: {},
        postATSJobCreationLoader: false,
        OrgList: [],
      };

    case actionTypes.SET_ATS_JOBS_PORTAL:
      return {
        ...state,
        searchKeyATS: action?.data?.pySearchKey || '',
        Country: action?.data?.Country || '',
        JobCasesList: [],
      };

    case actionTypes.POST_ATS_JOB_CREATION_LOADER:
      return {
        ...state,
        postATSJobCreationLoader: true,
      };

    case actionTypes.GET_EMP_DETAILS_SUCCESS:
      return {
        ...state,
        EmpLocData: action?.cases?.EmpLocData,
        HRLocData: action?.cases?.HRLocData,
        GetJobSKills: action?.cases?.GetJobSKills,
        CountryBasedOnLocCode: action?.cases?.CountryBasedOnLocCode,
        getEmpLoader: false,
      };

    case actionTypes.GET_EMP_DETAILS_FAILURE:
      return {
        ...state,
        empList: [],
        getEmpLoader: false,
      };

    case actionTypes.GET_EMP_DETAILS_LOADER:
      return {
        ...state,
        getEmpLoader: true,
      };
    case actionTypes.JOB_SEARCH_SUCCESS:
      return {
        ...state,
        jobSearchLoader: false,
        jobsList: action?.cases?.pxResults,
      };
    case actionTypes.JOB_SEARCH_FAILURE:
      return {
        ...state,
        jobSearchLoader: false,
        jobsList: [],
      };

    case actionTypes.JOB_SEARCH_LOADER:
      return {
        ...state,
        jobSearchLoader: true,
      };
    case actionTypes.APPLICANT_SUBMIT_SUCCESS:
      return {
        ...state,
        applicantData: action.cases,
        submitApplicantLoader: false,
      };
    case actionTypes.APPLICANT_SUBMIT_FAILURE:
      return {
        ...state,
        applicantData: {},
        submitApplicantLoader: false,
      };
    case actionTypes.APPLICANT_SUBMIT_LOADER:
      return {
        ...state,
        submitApplicantLoader: true,
      };

    case actionTypes.SET_DATA_ATS:
      return {
        ...state,
        applicantData: action?.data?.applicantData || {},
        bulkAssignMsg: action?.data?.bulkAssignMsg || '',
        jobDetails: {},
      };

    case actionTypes.JOBS_PAGINATION:
      return {
        ...state,
        pageNumber: action?.data?.pageNumber || 1,
        currentPageJobs: action?.data?.jobs || [],
      };

    case actionTypes.BULK_ASSIGN_SUCCESS:
      return {
        ...state,
        APLCommonpoolCaseList: action.cases.APLCommonpoolCaseList,
        bulkAssignMsg: action.cases.pyNote,
        bulkAssig: action.cases,
        bulkAssignLoader: false,
      };
    case actionTypes.BULK_ASSIGN_FAILURE:
      return {
        ...state,
        applicantData: {},
        bulkAssignMsg: '',
        bulkAssignLoader: false,
      };
    case actionTypes.BULK_ASSIGN_LOADER:
      return {
        ...state,
        bulkAssignLoader: true,
        bulkAssignMsg: '',
      };

    case actionTypes.JOB_DETAILS_SEARCH_SUCCESS:
      return {
        ...state,
        jobDetails: action?.cases?.JobDetails || {},
        jobDetailsLoader: false,
      };
    case actionTypes.JOB_DETAILS_SEARCH_FAILURE:
      return {
        ...state,
        jobDetails: {},
        jobDetailsLoader: false,
      };
    case actionTypes.JOB_DETAILS_SEARCH_LOADER:
      return {
        ...state,
        jobDetailsLoader: true,
      };

    case actionTypes.TEMP_SEARCH_SUCCESS:
      return {
        ...state,
        searchTempLoader: false,
        JobDescription: action?.cases?.JobDescription,
      };

    case actionTypes.TEMP_SEARCH_FAILURE:
      return {
        ...state,
        searchTempLoader: false,
      };

    case actionTypes.TEMP_SEARCH_LOADER:
      return {
        ...state,
        searchTempLoader: true,
      };

    case actionTypes.GET_BULK_DISPOSITION_EMAIL_SUCCESS:
      return {
        ...state,
        casesOfBulkEmailDisposition: action?.cases?.BulkEmailApplicantsList || [],
        BulkTempletList: action?.cases?.BulkTempletList || [],
        getCasesForDispositionEmailLoader: false,
      };

    case actionTypes.GET_BULK_DISPOSITION_EMAIL_FAILURE:
      return {
        ...state,
        getCasesForDispositionEmailLoader: false,
        casesOfBulkEmailDisposition: [],
      };

    case actionTypes.GET_BULK_DISPOSITION_EMAIL_LOADER:
      return {
        ...state,
        getCasesForDispositionEmailLoader: true,
      };

    case actionTypes.SEND_BULK_DISPOSITION_EMAIL_SUCCESS:
      return {
        ...state,
        sucessNoteEmailDisposition: action?.cases?.pyNote || '',
        sendDispositionEmailLoader: false,
      };

    case actionTypes.SEND_BULK_DISPOSITION_EMAIL_FAILURE:
      return {
        ...state,
        sendDispositionEmailLoader: false,
        sucessNoteEmailDisposition: '',
      };

    case actionTypes.SEND_BULK_DISPOSITION_EMAIL_LOADER:
      return {
        ...state,
        sendDispositionEmailLoader: true,
      };

    case actionTypes.CLEAR_DISPOSITION_EMAIL_DATA:
      return {
        ...state,
        casesOfBulkEmailDisposition: [],
        sucessNoteEmailDisposition: '',
      };
    case actionTypes.CLEAR_JOB_TEMP_DATA:
      return {
        ...state,
        JobDescription: '',
        //CountryBasedOnLocCode: [],
      };

    case actionTypes.SCHEDULE_INTERVIEW_SUCCESS:
      return {
        ...state,
        ScheduleInterviewLoader: false,
        notifyPanelLoader: false,
        InterviewScheduleData: action?.cases || {},
      };
    case actionTypes.SCHEDULE_INTERVIEW_FAILURE:
      return {
        ...state,
        ScheduleInterviewLoader: false,
        notifyPanelLoader: false,
      };
    case actionTypes.SCHEDULE_INTERVIEW_LOADER:
      return {
        ...state,
        ScheduleInterviewLoader: true,
      };

    case actionTypes.GET_PANEL_MEMBERS_SUCCESS:
      return {
        ...state,
        getPanelMembersLoader: false,
        InterviewPanelMembers: action?.cases?.InterviewPanelMembers || [],
        NoOfPanelMembers: parseInt(action?.cases?.NoOfPanelMembers) || 0,
      };
    case actionTypes.GET_PANEL_MEMBERS_FAILURE:
      return {
        ...state,
        getPanelMembersLoader: false,
        InterviewPanelMembers: [],
      };
    case actionTypes.GET_PANEL_MEMBERS_LOADER:
      return {
        ...state,
        getPanelMembersLoader: true,
      };
    case actionTypes.NOTIFY_PANEL_LOADER:
      return {
        ...state,
        notifyPanelLoader: true,
      };

    case actionTypes.CHANGE_INTERVIEWER_SUCCESS:
      return {
        ...state,
        changeInterviewerLoader: false,
      };

    case actionTypes.CHANGE_INTERVIEWER_FAILURE:
      return {
        ...state,
        changeInterviewerLoader: false,
      };

    case actionTypes.CHANGE_INTERVIEWER_LOADER:
      return {
        ...state,
        changeInterviewerLoader: true,
      };

    case actionTypes.RESEND_INVITE_SUCCESS:
      return {
        ...state,
        resendInviteLoader: false,
      };

    case actionTypes.RESEND_INVITE_FAILURE:
      return {
        ...state,
        resendInviteLoader: false,
      };

    case actionTypes.RESEND_INVITE_LOADER:
      return {
        ...state,
        resendInviteLoader: true,
      };

    case actionTypes.SEARCH_JOBS_FROM_JOBS_PAGE:
      return {
        ...state,
        ATSSearchKeyFromPortalLoader: false,
        JobCasesList: action?.cases?.JobCasesList || [],
      };

    case actionTypes.SEARCH_KEY_FROM_PORTAL_SUCCESS:
      return {
        ...state,
        ATSSearchKeyFromPortalLoader: false,
        JobDetailsKeywordList: action?.cases?.JobDetailsKeywordList || [],
      };
    case actionTypes.SEARCH_KEY_FROM_PORTAL_FAILURE:
      return {
        ...state,
        ATSSearchKeyFromPortalLoader: false,
        JobDetailsKeywordList: [],
        JobCasesList: [],
      };
    case actionTypes.SEARCH_KEY_FROM_PORTAL_LOADER:
      return {
        ...state,
        ATSSearchKeyFromPortalLoader: true,
      };

    default:
      return state;
  }
}
