import { actionTypes } from '../actions/actionTypes/loginActionTypes';

let initialState = {
  isLoggedIn: localStorage.getItem('isLoggedIn'),
  isInitChecked: false,
  loginLoader: false,
  uplaodedFileScanLoader: false,
  ResetPWDData: {},
  resetPWDLoader: false,
  ProfilePicURL: '',
  ProfilePicURLLoader: false,
  isProfilePicExist: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function loggedIn(state = initialState, action) {
  switch (action.type) {
    case actionTypes.IsLoggedIn:
      return {
        ...state,
        isLoggedIn: action.loggedInFlag,
        isInitChecked: true,
        loginLoader: false,
        uplaodedFileScanLoader: false,
      };
    case 'loginLoader':
      return {
        ...state,
        loginLoader: true,
        authType: action.authType,
      };

    case 'uplaodedFileScanLoader':
      return {
        ...state,
        uplaodedFileScanLoader: action.isLoading,
      };

    case actionTypes.RESET_PASSWORD_GET_OTP_SUCCESS:
      return {
        ...state,
        resetPWDLoader: false,
        ResetPWDData: action?.data,
      };

    case actionTypes.RESET_PASSWORD_GET_OTP_FAILURE:
      return {
        ...state,
        resetPWDLoader: false,
        ResetPWDData: {},
      };

    case actionTypes.RESET_PASSWORD_LOADER:
      return {
        ...state,
        resetPWDLoader: true,
      };

    case actionTypes.CLEAR_PWD_DATA:
      return {
        ...state,
        ResetPWDData: {},
      };

    case 'PROFILE_PIC_SUCCESS':
      return {
        ...state,
        ProfilePicURLLoader: false,
        ProfilePicURL: action.data.url,
        isProfilePicExist: action.data.isSuccess,
      };

    case 'PROFILE_PIC_FAILURE':
      return {
        ...state,
        ProfilePicURLLoader: false,
        ProfilePicURL: '',
      };

    case 'PROFILEPIC_LOADER':
      return {
        ...state,
        ProfilePicURLLoader: true,
      };

    default:
      return state;
  }
}
