export const actionTypes = {
  GET_ATS_JOB_CREATION_SUCCESS: 'GET_ATS_JOB_CREATION_SUCCESS',
  GET_ATS_JOB_CREATION_FAILURE: 'GET_ATS_JOB_CREATION_FAILURE',
  GET_ATS_JOB_CREATION_LOADER: 'SHOW_PAYROLL_LOADER',
  POST_ATS_JOB_CREATION_SUCCESS: 'POST_ATS_JOB_CREATION_SUCCESS',
  POST_ATS_JOB_CREATION_FAILURE: 'POST_ATS_JOB_CREATION_FAILURE',
  POST_ATS_JOB_CREATION_LOADER: 'POST_ATS_JOB_CREATION_LOADER',
  GET_EMP_DETAILS_FAILURE: 'GET_EMP_DETAILS_FAILURE',
  GET_EMP_DETAILS_SUCCESS: 'GET_EMP_DETAILS_SUCCESS',
  GET_EMP_DETAILS_LOADER: 'GET_EMP_DETAILS_LOADER',

  JOB_SEARCH_FAILURE: 'JOB_SEARCH_FAILURE',
  JOB_SEARCH_SUCCESS: 'JOB_SEARCH_SUCCESS',
  JOB_SEARCH_LOADER: 'JOB_SEARCH_LOADER',

  APPLICANT_SUBMIT_FAILURE: 'APPLICANT_SUBMIT_FAILURE',
  APPLICANT_SUBMIT_SUCCESS: 'APPLICANT_SUBMIT_SUCCESS',
  APPLICANT_SUBMIT_LOADER: 'APPLICANT_SUBMIT_LOADER',

  BULK_ASSIGN_FAILURE: 'BULK_ASSIGN_FAILURE',
  BULK_ASSIGN_SUCCESS: 'BULK_ASSIGN_SUCCESS',
  BULK_ASSIGN_LOADER: 'BULK_ASSIGN_LOADER',

  SET_DATA_ATS: 'SET_DATA_ATS',

  JOB_DETAILS_SEARCH_FAILURE: 'JOB_DETAILS_SEARCH_FAILURE',
  JOB_DETAILS_SEARCH_SUCCESS: 'JOB_DETAILS_SEARCH_SUCCESS',
  JOB_DETAILS_SEARCH_LOADER: 'JOB_DETAILS_SEARCH_LOADER',

  TEMP_SEARCH_FAILURE: 'TEMP_SEARCH_FAILURE',
  TEMP_SEARCH_SUCCESS: 'TEMP_SEARCH_SUCCESS',
  TEMP_SEARCH_LOADER: 'TEMP_SEARCH_LOADER',

  GET_BULK_DISPOSITION_EMAIL_FAILURE: 'GET_BULK_DISPOSITION_EMAIL_FAILURE',
  GET_BULK_DISPOSITION_EMAIL_SUCCESS: 'GET_BULK_DISPOSITION_EMAIL_SUCCESS',
  GET_BULK_DISPOSITION_EMAIL_LOADER: 'GET_BULK_DISPOSITION_EMAIL_LOADER',

  SEND_BULK_DISPOSITION_EMAIL_FAILURE: 'SEND_BULK_DISPOSITION_EMAIL_FAILURE',
  SEND_BULK_DISPOSITION_EMAIL_SUCCESS: 'SEND_BULK_DISPOSITION_EMAIL_SUCCESS',
  SEND_BULK_DISPOSITION_EMAIL_LOADER: 'SEND_BULK_DISPOSITION_EMAIL_LOADER',

  CLEAR_DISPOSITION_EMAIL_DATA: 'CLEAR_DISPOSITION_EMAIL_DATA',
  CLEAR_JOB_TEMP_DATA: 'CLEAR_JOB_TEMP_DATA',

  SCHEDULE_INTERVIEW_FAILURE: 'SCHEDULE_INTERVIEW_FAILURE',
  SCHEDULE_INTERVIEW_SUCCESS: 'SCHEDULE_INTERVIEW_SUCCESS',
  SCHEDULE_INTERVIEW_LOADER: 'SCHEDULE_INTERVIEW_LOADER',

  GET_PANEL_MEMBERS_FAILURE: 'GET_PANEL_MEMBERS_FAILURE',
  GET_PANEL_MEMBERS_SUCCESS: 'GET_PANEL_MEMBERS_SUCCESS',
  GET_PANEL_MEMBERS_LOADER: 'GET_PANEL_MEMBERS_LOADER',

  NOTIFY_PANEL_LOADER: 'NOTIFY_PANEL_LOADER',

  SET_JOBS_FILTER_VALUE: 'SET_JOBS_FILTER_VALUE',

  RESEND_INVITE_SUCCESS: 'RESEND_INVITE_SUCCESS',
  RESEND_INVITE_FAILURE: 'RESEND_INVITE_FAILURE',
  RESEND_INVITE_LOADER: 'RESEND_INVITE_LOADER',

  CHANGE_INTERVIEWER_SUCCESS: 'CHANGE_INTERVIEWER_SUCCESS',
  CHANGE_INTERVIEWER_FAILURE: 'CHANGE_INTERVIEWER_FAILURE',
  CHANGE_INTERVIEWER_LOADER: 'CHANGE_INTERVIEWER_LOADER',

  SEARCH_KEY_FROM_PORTAL_SUCCESS: 'SEARCH_KEY_FROM_PORTAL_SUCCESS',
  SEARCH_KEY_FROM_PORTAL_FAILURE: 'SEARCH_KEY_FROM_PORTAL_FAILURE',
  SEARCH_KEY_FROM_PORTAL_LOADER: 'SEARCH_KEY_FROM_PORTAL_LOADER',

  JOBS_PAGINATION: 'JOBS_PAGINATION',

  SET_ATS_JOBS_PORTAL: 'SET_ATS_JOBS_PORTAL',

  SEARCH_JOBS_FROM_JOBS_PAGE: 'SEARCH_JOBS_FROM_JOBS_PAGE',
};
