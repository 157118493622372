import { actionTypes } from '../actions/actionTypes/changePasswordActionTypes';

let initialState = {
  changePasswordLoader: false,
  resetForm: false,
  isPasswordChanged: false,
};

export function changePassword(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoader: false,
        resetForm: true,
        isPasswordChanged: action.cases.status === 'success' ? true : false,
      };

    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordLoader: false,
        resetForm: true,
        isPasswordChanged: false,
      };

    case actionTypes.CHANGE_PASSWORD_LOADER:
      return {
        ...state,
        changePasswordLoader: true,
      };

    case actionTypes.RESET_FORM_RESET_BOOLEAN:
      return {
        ...state,
        resetForm: false,
        isPasswordChanged: false,
      };

    default:
      return state;
  }
}
