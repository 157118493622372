import { actionTypes } from '../actions/actionTypes/LeaveManagementActionTypes';

let initialState = {
  OrgList: [],
  empList: [],
  employementList: [],
  getLeaveManagementLoader: false,
  getEmpListLoader: false,
  getLeaveDetailsLoader: false,
  EmployeedetailsList: [],
  EmpLeaveHistory: [],
  LeaveBalanceHistory:[],
  LeaveBalanceList: [],
  submitLeaveManagementLoader: false,
  resetFilters: false,
  OrgLeaveCategoryList: [],
  LeaveMgmntCaseList: [],
  BulkLeaveMgmntCaseList: [],
};
/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function LeaveManagement(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LEAVEMANAGEMENT_SUCCESS:
      return {
        ...state,
        employementList: action.cases.EmploymentTypeList,
        OrgList: action.cases.OrgList,
        LeaveMgmntCaseList: action?.cases?.LeaveMgmntCaseList || [],
        BulkLeaveMgmntCaseList: action?.cases?.BulkLeaveMgmntCaseList || [],
        getLeaveManagementLoader: false,
      };

    case actionTypes.GET_LEAVEMANAGEMENT_FAILURE:
      return {
        ...state,
        OrgList: [],
        employementList: [],
        getLeaveManagementLoader: false,
      };

    case actionTypes.SHOW_LEAVEMANAGEMENT_LOADER_GET:
      return {
        ...state,
        getLeaveManagementLoader: true,
      };

    case actionTypes.GET_EMP_SUCCESS:
      return {
        ...state,
        empList: action.cases.EmployeeList,
        getEmpListLoader: false,
      };

    case actionTypes.GET_EMP_FAILURE:
      return {
        ...state,
        empList: [],
        getEmpListLoader: false,
      };

    case actionTypes.GET_EMP_LOADER:
      return {
        ...state,
        getEmpListLoader: true,
      };

    case actionTypes.GET_LEAVE_DETAILS_SUCCESS:
      return {
        ...state,
        getLeaveDetailsLoader: false,
        EmployeedetailsList: action.cases?.EmployeedetailsList || [],
        LeaveBalanceList: action.cases?.LeaveBalanceList || [],
        EmpLeaveHistory: action.cases?.EmpLeaveHistory || [],
        LeaveBalanceHistory: action.cases?.LeaveBalanceHistory || [],
        OrgLeaveCategoryList: action.cases?.OrgLeaveCategoryList || [],
      };
    case actionTypes.GET_LEAVE_DETAILS_FAILURE:
      return {
        ...state,
        getLeaveDetailsLoader: false,
        EmployeedetailsList: [],
        LeaveBalanceList: [],
        EmpLeaveHistory: [],
        LeaveBalanceHistory:[],
        OrgLeaveCategoryList: [],
      };
    case actionTypes.GET_LEAVE_DETAILS_LOADER:
      return {
        ...state,
        getLeaveDetailsLoader: true,
      };

    case actionTypes.SUBMIT_LEAVE_MANAGEMENT_SUCCESS:
      return {
        ...state,
        submitLeaveManagementLoader: false,
        resetFilters: true,
      };
    case actionTypes.SUBMIT_LEAVE_MANAGEMENT_FAILURE:
      return {
        ...state,
        submitLeaveManagementLoader: false,
        resetFilters: false,
      };
    case actionTypes.SUBMIT_LEAVE_MANAGEMENT_LOADER:
      return {
        ...state,
        submitLeaveManagementLoader: true,
      };
    case actionTypes.CLEAR_LM_DATA:
      return {
        ...state,
        EmployeedetailsList: [],
        LeaveBalanceList: [],
        EmpLeaveHistory: [],
        LeaveBalanceHistory:[],
        resetFilters: false,
        empList: [],
      };

    default:
      return state;
  }
}
