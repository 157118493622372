import Noty from 'noty';

export function noty(type, text, duration) {
  if (type === 'success') {
    return new Noty({
      type: 'success',
      text: text,
      theme: 'sunset',
      timeout: duration || 6000,
      layout: 'topRight',
      closeWith: ['click', 'button'],
    }).show();
  } else {
    return new Noty({
      type: 'error',
      text: text,
      theme: 'sunset',
      timeout: duration || 3000,
      closeWith: ['click', 'button'],
      layout: 'topRight',
    }).show();
  }
}
