export const actionTypes = {
  GET_TIMESHEET_SUCCESS: 'GET_TIMESHEET_SUCCESS',
  GET_TIMESHEET_FAILURE: 'GET_TIMESHEET_FAILURE',
  POST_TIMESHEET_SUCCESS: 'POST_TIMESHEET_SUCCESS',
  POST_TIMESHEET_FAILURE: 'POST_TIMESHEET_FAILURE',
  SHOW_LOADER_TIMESHEET: 'SHOW_LOADER_TIMESHEET',
  GET_TEAM_TIMESHEET_SUCCESS: 'GET_TEAM_TIMESHEET_SUCCESS',
  GET_TEAM_TIMESHEET_FAILURE: 'GET_TEAM_TIMESHEET_FAILURE',
  POST_TIMESHEET_STATUS_SUCCESS: 'POST_TIMESHEET_STATUS_SUCCESS',
  POST_TIMESHEET_STATUS_FAILURE: 'POST_TIMESHEET_STATUS_FAILURE',
  SHOW_LOADER_TIMESHEET_SUCCESS: 'SHOW_LOADER_TIMESHEET_SUCCESS',
  SHOW_TEAM_TIMESHEETS_LOADER: 'SHOW_TEAM_TIMESHEETS_LOADER',

  TIMESHEET_BULK_ACTION_BY_PROJECT_SUCESS: 'TIMESHEET_BULK_ACTION_BY_PROJECT_SUCESS',
  TIMESHEET_BULK_ACTION_BY_PROJECT_FAILURE: 'TIMESHEET_BULK_ACTION_BY_PROJECT_FAILURE',

  NO_ACTION: 'NO_ACTION',

  DOWNLOAD_TIMESHEET_REPORT_USER_SUCCESS: 'DOWNLOAD_TIMESHEET_REPORT_USER_SUCCESS',
  DOWNLOAD_TIMESHEET_REPORT_USER_FAILURE: 'DOWNLOAD_TIMESHEET_REPORT_USER_FAILURE',
  DOWNLOAD_TIMESHEET_REPORT_USER_LOADER: 'DOWNLOAD_TIMESHEET_REPORT_USER_LOADER',

  DELETE_TIMESHEET_SUCCESS: 'DELETE_TIMESHEET_SUCCESS',
  DELETE_TIMESHEET_FAILURE: 'DELETE_TIMESHEET_FAILURE',
  DELETE_TIMESHEET_LOADER: 'DELETE_TIMESHEET_LOADER',
};
