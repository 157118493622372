export const actionTypes = {
  GET_ENROLLED_BENEFITS_SUCCESS: 'GET_ENROLLED_BENEFITS_SUCCESS',
  GET_ENROLLED_BENEFITS_FAILURE: 'GET_ENROLLED_BENEFITS_FAILURE',
  GET_ENROLLED_BENEFITS_LOADER: 'GET_ENROLLED_BENEFITS_LOADER',
  POST_ENROLLED_BENEFITS_SUCCESS: 'POST_ENROLLED_BENEFITS_SUCCESS',
  POST_ENROLLED_BENEFITS_FAILURE: 'POST_ENROLLED_BENEFITS_FAILURE',
  POST_ENROLLED_BENEFITS_LOADER: 'POST_ENROLLED_BENEFITS_LOADER',
  SET_DATA_BE: 'SET_DATA_BE',
  GET_PREMIUM_CALCULATIONS_SUCCESS: 'GET_PREMIUM_CALCULATIONS_SUCCESS',
  GET_PREMIUM_CALCULATIONS_FAILURE: 'GET_PREMIUM_CALCULATIONS_FAILURE',
  GET_PREMIUM_CALCULATIONS_LODER: 'GET_PREMIUM_CALCULATIONS_LODER',
  GET_QLE_PREMIUM_CALCULATIONS_SUCCESS: 'GET_QLE_PREMIUM_CALCULATIONS_SUCCESS',
  GET_QLE_PREMIUM_CALCULATIONS_FAILURE: 'GET_QLE_PREMIUM_CALCULATIONS_FAILURE',

  GET_TEAM_ENROLLED_BENEFITS_SUCCESS: 'GET_TEAM_ENROLLED_BENEFITS_SUCCESS',
  GET_TEAM_ENROLLED_BENEFITS_FAILURE: 'GET_TEAM_ENROLLED_BENEFITS_FAILURE',
  GET_TEAM_ENROLLED_BENEFITS_LOADER: 'GET_TEAM_ENROLLED_BENEFITS_LOADER',

  POST_TEAM_ENROLLED_BENEFITS_SUCCESS: 'POST_TEAM_ENROLLED_BENEFITS_SUCCESS',
  POST_TEAM_ENROLLED_BENEFITS_FAILURE: 'POST_TEAM_ENROLLED_BENEFITS_FAILURE',
  POST_TEAM_ENROLLED_BENEFITS_LOADER: 'POST_TEAM_ENROLLED_BENEFITS_LOADER',

  OPEN_BE_CASE_TEAMS_SUCCESS: 'OPEN_BE_CASE_TEAMS_SUCCESS',
  OPEN_BE_CASE_TEAMS_FAILURE: 'OPEN_BE_CASE_TEAMS_FAILURE',

  VIEW_FAILURE: 'VIEW_FAILURE',
  VIEW_SUCCESS: 'VIEW_SUCCESS',
  VIEW_LOADER: 'VIEW_LOADER',
  RESET_VIEWDATA: 'RESET_VIEWDATA',

  SET_DATA_BE_TEAM: 'SET_DATA_BE_TEAM',

  GET_DETAILS_401K_SUCCESS: 'GET_DETAILS_401K_SUCCESS',
  GET_DETAILS_401K_FAILURES: 'GET_DETAILS_401K_FAILURES',

  SUBMIT_401K_DETAILS_SUCCESS: 'SUBMIT_401K_DETAILS_SUCCESS',
  SUBMIT_401K_DETAILS_FAILURE: 'SUBMIT_401K_DETAILS_FAILURE',
  SUBMIT_401K_DETAILS_LOADER: 'SUBMIT_401K_DETAILS_LOADER',

  CHANGE_401K_PLAN_SUCCESS: 'CHANGE_401K_PLAN_SUCCESS',
  CHANGE_401K_PLAN_FAILURE: 'CHANGE_401K_PLAN_FAILURE',

  DISCONTINUE_PLAN_SUCCESS: 'DISCONTINUE_PLAN_SUCCESS',
  DISCONTINUE_PLAN_FAILURE: 'DISCONTINUE_PLAN_FAILURE',

  SHOW_DOB_SSN_SUCCESS: 'SHOW_DOB_SSN_SUCCESS',
  SHOW_DOB_SSN_FAILURE: 'SHOW_DOB_SSN_FAILURE',
  SHOW_DOB_SSN_LOADER: 'SHOW_DOB_SSN_LOADER',

  VIEW_401K_AUDIT_SUCCESS: 'VIEW_401K_AUDIT_SUCCESS',
  VIEW_401K_AUDIT_LOADER: 'VIEW_401K_AUDIT_LOADER',
  VIEW_401K_AUDIT_FAILURE: 'VIEW_401K_AUDIT_FAILURE'
};
